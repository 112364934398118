import BasicInfo from "@components/dashboard/details/BasicInfo";
import Agregator from "@components/dashboard/value/Agregator";
import UnitAccordion from "@components/dashboard/value/accordion/UnitAccordion";

import { useProjectContext } from "@contexts/projects";
import { isBuildingTrial } from "@utils/checkPermiossion";

const Value = () => {
  const { activeProject } = useProjectContext();
  const buildingTrial = isBuildingTrial();

  if (!activeProject) return <></>;

  return (
    <>
      <BasicInfo />
      {!buildingTrial && <Agregator />}
      <UnitAccordion />
    </>
  );
};

export default Value;