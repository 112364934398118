// import loginService from "@services/auth/login";
import Cookies from "universal-cookie";
import {
  useDashboardUIActions,
} from "@contexts/dashboardUI";
import { useAuthContext } from ".";

const cookies = new Cookies();

export default (state, dispatch) => {
  const login = async (data) => {
    const response = await fetch(`${process.env.SERVER_URL}/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    })

    if (response.ok) {
      const { user } = await response.json();
      cookies.set("auth", user)
      dispatch({
        type: "LOGGED_IN",
        data: user,
      });
    } else {
      dispatch({
        type: "WRONG_CREDENTIALS",
      });
    }
  };

  const logout = async (data) => {
    cookies.remove("auth", { path: "/" });
    dispatch({
      type: "LOGGED_OUT",
    });
  };

  const checkAuth = async () => {
    // const claim = cookies.get("claim")
    // console.log(claim)
    const remember = cookies.get("auth");
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/auth/checkUserUpdates?token=${remember.token}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    dispatch({
      type: "COOKIE_CHECKED",
      data: response.user,
    });
    // dispatch({ type: "COOKIE_CHECKED" });
  };

  const checkAlphaKey = async (alphaKey) => {
    const response = await fetch(`${process.env.SERVER_URL}/auth/alpha-key`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ alphaKey })
    })

    if (response.ok) {
      const { email } = await response.json();
      if (email) {
        dispatch({
          type: "APPROVED_ALPHA_KEY",
          data: email
        })
      } else {
        dispatch({
          type: "WRONG_ALPHA_KEY"
        })
      }
    } else {
      dispatch({
        type: "WRONG_ALPHA_KEY"
      })
    }
  }

  const completeRegistration = async (data) => {
    const response = await fetch(`${process.env.SERVER_URL}/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data, email: state.alphaKeyEmail })
    })

    if (response.ok) {
      login({
        email: state.alphaKeyEmail,
        password: data.password,
        keepConnected: true
      })
    } else {
      dispatch({
        type: "WRONG_ALPHA_KEY"
      })
    }
  }

  const updatePassword = async data => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/auth/update`, {
      method: "POST",
      body: JSON.stringify(data)
    })
    setIsLoading(false)
    return response
  }

  const createUser = async data => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/auth/register`, {
      method: "POST",
      body: JSON.stringify(data)
    }).then(res => { return res.json() })
    setIsLoading(false)
    return response
  }

  const updateUser = async data => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/auth/updateCompanies`, {
      method: "POST",
      body: JSON.stringify(data)
    }).then(res => { return res.json() })
    setIsLoading(false)
    return response
  }

  const resetPassword = async data => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/auth/resetPassword`, {
      method: "POST",
      body: JSON.stringify(data)
    }).then(res => { return res.json() })
    setIsLoading(false)
    return response
  }

  const listUsers = async data => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/auth/listUsers`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  return {
    login,
    logout,
    checkAuth,
    checkAlphaKey,
    completeRegistration,
    updatePassword,
    createUser,
    listUsers,
    updateUser,
    resetPassword
  };
};
