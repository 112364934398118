import { NavLink } from "react-router-dom";
import Logo from "@assets/images/ampli-logo.svg";

import {
  useDashboardUIActions,
  useDashboardUIContext,
} from "@contexts/dashboardUI";
import { useProjectContext } from "@contexts/projects";
import { useBimActions } from "@contexts/bim";
import { useAuthContext } from "@contexts/auth";
import { isBuildingTrial } from "@utils/checkPermiossion";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCostsActions } from "@contexts/costs";
import { checkRoles } from "@utils/permissions";

const checkPath = (e) => {
  const { pathname } = useLocation();
  if (pathname.includes(e)) return true;
  return false
}

const Sidebar = () => {
  const { activeProject } = useProjectContext();
  const { showSidebar } = useDashboardUIContext();
  const { toggleSidebar } = useDashboardUIActions();
  const { getProjects, selectProject } = useBimActions()
  const { getBuildings, selectBuilding } = useCostsActions()
  const { user } = useAuthContext();
  const [visibles, setVisibles] = useState({
    backoffice: checkPath('backoffice'),
    bim: checkPath('bim'),
    costs: checkPath('costs')
  })
  const [bimProjects, setBimProjects] = useState([])
  const [costsBuildings, setCostsBuildings] = useState([])

  const handleOpenClose = (e) => {
    const name = e.target.getAttribute("name")
    setVisibles((prev) => ({
      ...prev,
      [name]: !prev[name]
    }))
  }

  const getBimProjects = async () => {
    const res = await getProjects()
    setBimProjects(res.res)
  }

  const getCostsBuildings = async () => {
    const res = await getBuildings()
    setCostsBuildings(res.res)
  }

  useEffect(() => {
    if (visibles.bim) {
      getBimProjects()
    }
    if (visibles.costs) {
      getCostsBuildings()
    }
  }, [visibles])

  const handleBimProjectChange = (e) => {
    const project = bimProjects[e.target.value]
    selectProject(project)
  }

  const handleCostsBuildingChange = (e) => {
    const building = costsBuildings[e.target.value]
    selectBuilding(building)
  }

  return (
    showSidebar && (
      <aside>
        <div>
          <div className="flex gap-16 align-center justify-content-space-between padding-horizontal-24 padding-horizontal-32-laptop-l">
            <figure id="dashboard-logo">
              <img src={Logo} alt="Ampli" />
            </figure>
            {showSidebar && (<button className="toggle-menu-button" onClick={toggleSidebar}>
              <i
                className="icon icon-width-24 icon-angle-double-left background-accent"
                aria-hidden={true}
              ></i>
            </button>
            )}
          </div>
          <nav id="dashboard-nav" className="grid margin-top-48">
            {!isBuildingTrial() && (
              <NavLink to={`/dashboard/${activeProject?._id}`} end>
                Dashboard
              </NavLink>
            )}
            <NavLink to={`/dashboard/valor-agregado/${activeProject?._id}`}>{!isBuildingTrial() ? "Valor agregado" : "Orçamento"}</NavLink>
            <NavLink to={`/dashboard/projecao-financeira/${activeProject?._id}`}>
              Projeção financeira
            </NavLink>
            <NavLink to={`/dashboard/curva/${activeProject?._id}`}>
              Curva ABC
            </NavLink>
            <NavLink to={`/dashboard/adiantamentos/${activeProject?._id}`}>
              Adiantamentos
            </NavLink>
            {checkRoles('admin') && <>
              <div className="sidebar-section-header">
                <button
                  className={`accordion-toggle ${visibles.backoffice ? "active" : ""}`}
                />
                <span
                  onClick={handleOpenClose}
                  className="menu-header"
                  name='backoffice'
                >
                  Backoffice
                </span>
              </div>
              {visibles.backoffice && (
                <div className="submenu">
                  <NavLink to={`/dashboard/backoffice/new-user`}>
                    New user
                  </NavLink>
                  <NavLink to={`/dashboard/backoffice/users`}>
                    Users
                  </NavLink>
                  <NavLink to={`/dashboard/backoffice/new-company`}>
                    New Company
                  </NavLink>
                  <NavLink to={`/dashboard/backoffice/new-building`}>
                    New Building
                  </NavLink>
                </div>
              )}
            </>
            }
            {checkRoles('costs') && <>
              <div className="sidebar-section-header">
                <button
                  className={`accordion-toggle ${visibles.costs ? "active" : ""}`}
                // onClick={handleToggle}
                />
                <span
                  onClick={handleOpenClose}
                  className="menu-header"
                  name="costs"
                >
                  Custos
                </span>
              </div>
              {visibles.costs && (
                <div className="submenu">
                  <select className="bim-project-select" onChange={handleCostsBuildingChange}>
                    {costsBuildings.map((i, idx) => {
                      return <option key={i._id} value={idx}>{i.name}</option>
                    })}
                  </select>
                  <NavLink to={`/dashboard/costs/obras`}>
                    Obras
                  </NavLink>
                  <NavLink to={`/dashboard/costs/parametros`}>
                    Parâmetros
                  </NavLink>
                  <NavLink to={`/dashboard/costs/unidades`}>
                    Unidades
                  </NavLink>
                  <NavLink to={`/dashboard/costs/indices`}>
                    Índices
                  </NavLink>
                  <NavLink to={`/dashboard/costs/itens`}>
                    Itens
                  </NavLink>
                  <NavLink to={`/dashboard/costs/cub`}>
                    CUB
                  </NavLink>
                  <NavLink to={`/dashboard/costs/resumos`}>
                    Orçamentos Resumidos
                  </NavLink>
                  <NavLink to={`/dashboard/costs/comparacao`}>
                    Comparação de Orçamentos
                  </NavLink>
                  <NavLink to={`/dashboard/costs/fornecedores`}>
                    Fornecedores
                  </NavLink>
                </div>
              )}
            </>}
            {checkRoles('bim') && (
              <>
                <div className="sidebar-section-header">
                  <button
                    className={`accordion-toggle ${visibles.bim ? "active" : ""}`}
                  // onClick={handleToggle}
                  />
                  <span
                    onClick={handleOpenClose}
                    className="menu-header"
                    name='bim'
                  >
                    BIM
                  </span>
                </div>
                {visibles.bim && (
                  <div className="submenu">
                    <select className="bim-project-select" onChange={handleBimProjectChange}>
                      {bimProjects.map((i, idx) => {
                        return <option key={i._id} value={idx}>{i.name}</option>
                      })}
                    </select>
                    <NavLink to={`/dashboard/bim/cadastro`}>
                      Cadastro de Projetos
                    </NavLink>
                    <NavLink to={`/dashboard/bim/entregaveis`}>
                      Controle de Entregáveis
                    </NavLink>
                    <NavLink to={`/dashboard/bim/checklist`}>
                      Checklist de Verificação
                    </NavLink>
                    <NavLink to={`/dashboard/bim/classificacao`}>
                      Classificação de Apontamento
                    </NavLink>
                    <NavLink to={`/dashboard/bim/opcoes`}>
                      Opções
                    </NavLink>
                    <NavLink to={`/dashboard/bim/licoes`}>
                      Lições Aprendidas
                    </NavLink>
                  </div>
                )}
              </>
            )}

          </nav>
        </div>
      </aside>
    )
  );
};

export default Sidebar;
