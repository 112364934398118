import { useState, useEffect } from "react";
import { useBimActions } from "@contexts/bim";
import React from "react";

const defaultLesson = {
  building: "",
  typology: "",
  discipline: "",
  step: "",
  verification: "",
  lesson: "",
  conflict: "",
  resolution: "",
};

const LessonsLearned = () => {
  const { getLessons, updateLesson, deleteLesson } = useBimActions();
  const [lessons, setLessons] = useState([]);
  const [filteredLessons, setFilteredLessons] = useState([]);  
  const [showFilters, setShowFilters] = useState(true);  


  const [filters, setFilters] = useState({
    building: "",
    typology: "Todas",
    discipline: "",
    step: "Todas",
    verification: "Todas",
  });
  const [lessonInfo, setLessonInfo] = useState(defaultLesson);
  const [modalOpen, setModalOpen] = useState(false); 
  const [selectedLesson, setSelectedLesson] = useState(null); 

  useEffect(() => {
    fetchLessons();
  }, [filters]);
  
  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const res = await getLessons();
        console.log("Resposta da API:", res);
        setLessons(res.res || []);  
        setFilteredLessons(res.res || []);  
      } catch (error) {
        console.error("Erro ao buscar lições aprendidas:", error);
      }
    };
    fetchLessons();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = lessons;

      if (filters.building) {
        filtered = filtered.filter((lesson) =>
          lesson.building.toLowerCase().includes(filters.building.toLowerCase())
        );
      }

      if (filters.typology !== "Todas") {
        filtered = filtered.filter((lesson) => lesson.typology === filters.typology);
      }

      if (filters.discipline) {
        filtered = filtered.filter((lesson) => lesson.discipline === filters.discipline);
      }

      if (filters.step !== "Todas") {
        filtered = filtered.filter((lesson) => lesson.step === filters.step);
      }

      if (filters.verification !== "Todas") {
        filtered = filtered.filter((lesson) => lesson.verification === filters.verification);
      }

      setFilteredLessons(filtered);  
    };

    applyFilters();  
  }, [filters, lessons]);  

  const fetchLessons = async () => {
    try {
      console.log("Filtros atuais:", filters);
      const res = await getLessons(filters);
      console.log("Resposta da API:", res);
      setLessons(res.res || []);
    } catch (error) {
      console.error("Erro ao buscar lições aprendidas:", error);
    }
  };

  const handleChange = (event, setState) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  const handleSend = async () => {
    try {
      await updateLesson(lessonInfo);
      resetLesson();
      fetchLessons();
    } catch (error) {
      console.error("Erro ao atualizar lição:", error);
    }
  };

  const handleDelete = async (lessonId) => {
    try {
      await deleteLesson(lessonId);
      
      // Remove lição do estado
      const updatedLessons = lessons.filter((lesson) => lesson.id !== lessonId);
      setLessons(updatedLessons);
      setFilteredLessons(updatedLessons);
    } catch (error) {
      console.error("Erro ao excluir lição:", error);
    }
  };


  const resetLesson = () => {
    setLessonInfo(defaultLesson);
  };

  const openModal = (lesson) => {
    setSelectedLesson(lesson);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedLesson(null);
  };



  return (
    <div className="lessons-learned">
      <h2 style={{ fontWeight: 'bold' }}>Filtrar Lição Aprendida</h2>
      <div style={{ marginBottom: '16px' }}></div> 

      <div style={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gap: "16px", marginBottom: "16px" }}>
        <div>
          <label>Empreendimento/Cliente:</label>
          <input
            type="text"
            name="building"
            value={filters.building}
            onChange={(e) => handleChange(e, setFilters)}
          />
        </div>

        <div>
          <label>Tipologia:</label>
          <select
            name="typology"
            value={filters.typology}
            onChange={(e) => handleChange(e, setFilters)}
          >
            <option value="Todas">Todas</option>
            <option value="Torre Única">Torre Única</option>
            <option value="Múltiplas Torres">Múltiplas Torres</option>
            <option value="Horizontal">Horizontal</option>
            <option value="Misto">Misto</option>
            <option value="Outro">Outro</option>
          </select>
        </div>

        <div>
          <label>Disciplinas:</label>
          <select
            name="discipline"
            value={filters.discipline}
            onChange={(e) => handleChange(e, setFilters)}
          >
            <option value="">Todas</option>
            <option value="Furações">Furações</option>
            <option value="Cronograma">Cronograma</option>
            <option value="Contenção">Contenção</option>
            <option value="Fundações">Fundações</option>
            <option value="Estrutura">Estrutura</option>
            <option value="Arquitetura">Arquitetura</option>
          </select>
        </div>

        <div>
          <label>Etapa do Projeto:</label>
          <select
            name="step"
            value={filters.step}
            onChange={(e) => handleChange(e, setFilters)}
          >
            <option value="Todas">Todas</option>
            <option value="Projeto Básico">Projeto Básico</option>
            <option value="Projeto Executivo">Projeto Executivo</option>
            <option value="Obra">Obra</option>
            <option value="Pós-Obra">Pós-Obra</option>
          </select>
        </div>

        <div>
          <label>Etapa a ser verificada:</label>
          <select
            name="verification"
            value={filters.verification}
            onChange={(e) => handleChange(e, setFilters)}
          >
            <option value="Todas">Todas</option>
            <option value="Projeto Básico">Projeto Básico</option>
            <option value="Projeto Executivo">Projeto Executivo</option>
            <option value="Obra">Obra</option>
            <option value="Pós-Obra">Pós-Obra</option>
          </select>
        </div>

        <div>
          {/* <button onClick={() => setFilters({})} className="button button-secondary">Limpar Filtros</button>
          <button onClick={fetchLessons} className="button button-accent">Filtrar</button> */}
        </div>
      </div>

      <button onClick={resetLesson} className="button button-secondary margin-bottom-16">
        Nova Lição
      </button>

      <div className="lesson-form" style={{ marginBottom: "16px" }}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "16px" }}>
          <div>
            <label>Empreendimento/Cliente:</label>
            <input
              type="text"
              name="building"
              value={lessonInfo.building}
              onChange={(e) => handleChange(e, setLessonInfo)}
            />
          </div>

          <div>
            <label>Tipologia:</label>
            <select
              name="typology"
              value={lessonInfo.typology}
              onChange={(e) => handleChange(e, setLessonInfo)}
            >
              <option value="">Selecione</option>
              <option value="Torre Única">Torre Única</option>
              <option value="Múltiplas Torres">Múltiplas Torres</option>
              <option value="Horizontal">Horizontal</option>
              <option value="Misto">Misto</option>
              <option value="Outro">Outro</option>
            </select>
          </div>

          <div>
            <label>Disciplinas:</label>
            <select
              name="discipline"
              value={lessonInfo.discipline}
              onChange={(e) => handleChange(e, setLessonInfo)}
            >
              <option value="">Selecione</option>
              <option value="Furações">Furações</option>
              <option value="Cronograma">Cronograma</option>
              <option value="Contenção">Contenção</option>
              <option value="Fundações">Fundações</option>
              <option value="Estrutura">Estrutura</option>
              <option value="Arquitetura">Arquitetura</option>
            </select>
          </div>

          <div>
            <label>Etapa do Projeto:</label>
            <select
              name="step"
              value={lessonInfo.step}
              onChange={(e) => handleChange(e, setLessonInfo)}
            >
              <option value="">Selecione</option>
              <option value="Projeto Básico">Projeto Básico</option>
              <option value="Projeto Executivo">Projeto Executivo</option>
              <option value="Obra">Obra</option>
              <option value="Pós-Obra">Pós-Obra</option>
            </select>
          </div>

          <div>
            <label>Etapa a ser verificada:</label>
            <select
              name="verification"
              value={lessonInfo.verification}
              onChange={(e) => handleChange(e, setLessonInfo)}
            >
              <option value="">Selecione</option>
              <option value="Projeto Básico">Projeto Básico</option>
              <option value="Projeto Executivo">Projeto Executivo</option>
              <option value="Obra">Obra</option>
              <option value="Pós-Obra">Pós-Obra</option>
            </select>
          </div>

          <div>
            <label>Conflito Encontrado:</label>
            <textarea
              name="conflict"
              value={lessonInfo.conflict}
              onChange={(e) => handleChange(e, setLessonInfo)}
            ></textarea>
          </div>

          <div>
            <label>Resolução do Problema:</label>
            <textarea
              name="resolution"
              value={lessonInfo.resolution}
              onChange={(e) => handleChange(e, setLessonInfo)}
            ></textarea>
          </div>

          <div>
            <label>Lição Aprendida:</label>
            <textarea
              name="lesson"
              value={lessonInfo.lesson}
              onChange={(e) => handleChange(e, setLessonInfo)}
            ></textarea>
          </div>

          <div>
            <button onClick={handleSend} className="button button-accent">
              Enviar
            </button>
          </div>
        </div>
      </div>

      <div className="lesson-list" style={{ marginTop: "16px" }}>
        {filteredLessons.length > 0 ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(7, 1fr)",
              gap: "16px",
              textAlign: "center", 
            }}
          >
            <p style={{ fontWeight: "bold" }}>Empreendimento/Cliente</p>
            <p style={{ fontWeight: "bold" }}>Tipologia</p>
            <p style={{ fontWeight: "bold" }}>Disciplinas</p>
            <p style={{ fontWeight: "bold" }}>Etapa</p>
            <p style={{ fontWeight: "bold" }}>Lição Aprendida</p>
            <p style={{ fontWeight: "bold" }}>Etapa Verificação</p>
            <p style={{ fontWeight: "bold" }}>Ações</p>

            {filteredLessons.map((lesson) => (
              <React.Fragment key={lesson.id}>
                <p>{lesson.building}</p>
                <p>{lesson.typology}</p>
                <p>{lesson.discipline}</p>
                <p>{lesson.step}</p>
                <p>{lesson.lesson}</p>
                <p>{lesson.verification}</p>
                <p style={{ display: "flex", gap: "16px" }}>
                  <button onClick={() => setLessonInfo(lesson)}>Editar</button>
                  <button onClick={() => handleDelete(lesson.id)}>Excluir</button>
                  <button onClick={() => openModal(lesson)}>Detalhes</button>
                </p>
              </React.Fragment>
            ))}
          </div>
        ) : (
          <p>Nenhuma lição encontrada.</p>
        )}
      </div>

      {modalOpen && selectedLesson && (
        <div className="modal-overlay" onClick={closeModal} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()} style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px', width: '300px' }}>
            <h3 style={{ textAlign: 'center', marginBottom: '16px' }}>Detalhes da Lição</h3>
            <p style={{ marginBottom: '12px' }}>
              <span style={{ fontWeight: 'bold' }}>Conflito encontrado:</span> 
              <textarea
                value={selectedLesson.conflict}
                readOnly
                style={{
                  width: '100%',
                  minHeight: '80px',
                  maxHeight: '300px',
                  resize: 'none', 
                  overflowY: 'auto', 
                  marginTop: '8px',
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  fontSize: '14px'
                }}
              />
            </p>
            <p style={{ marginBottom: '12px' }}>
              <span style={{ fontWeight: 'bold' }}>Resolução do Problema:</span> 
              <textarea
                value={selectedLesson.resolution}
                readOnly
                style={{
                  width: '100%',
                  minHeight: '80px',
                  maxHeight: '300px',
                  resize: 'none',
                  overflowY: 'auto', 
                  marginTop: '8px',
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  fontSize: '14px'
                }}
              />
            </p>
            <div style={{ textAlign: 'center' }}>
              <button className="modal-close-button" onClick={closeModal} style={{ marginTop: '12px' }}>Fechar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LessonsLearned;
