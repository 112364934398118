import { Route, Routes, Navigate } from "react-router-dom";

import { useAuthActions, useAuthContext } from "@contexts/auth";
import AuthProvider from "@contexts/auth";
import Helmet from "@components/Helmet";
import ScrollToTop from "@components/ScrollToTop";

import Access from "@containers/Access";
import Login from "@outlets/access/Login";
import AlphaKey from "@outlets/access/AlphaKey";
import Register from "@outlets/access/Register";
import PasswordResetRequest from "@outlets/access/PasswordResetRequest";
import PasswordCreation from "@outlets/access/PasswordCreation";
import CodeValidation from "@outlets/access/CodeValidation";
import Dashboard from "@containers/Dashboard";
import Details from "@outlets/dashboard/Details";
import Value from "@outlets/dashboard/Value";
import Projection from "@outlets/dashboard/Projection";
import AppContainer from "@containers/AppContainer";
import DashboardContainer from "@containers/DashboardContainer";

import NewUser from "@outlets/backoffice/newUser";
import Users from "@outlets/backoffice/users";
import NewCompany from "@outlets/backoffice/newCompany";
import NewBuilding from "@outlets/backoffice/newBuilding";
import Curve from "@outlets/dashboard/Curve";
import Costs from "@outlets/dashboard/Costs";
import Advancements from "@outlets/dashboard/Advancements";
import Entry from "@outlets/costs/Entry";
import Params from "@outlets/costs/Params";
import Items from "@outlets/costs/Items";
import MeasurementUnits from "@outlets/costs/MeasurementUnits";
import Indexers from "@outlets/costs/Indexers";
import Comparison from "@outlets/costs/Comparison";
import Creditor from "@outlets/costs/Creditor";

import Indexes from "@outlets/costs/Indexes";
import Project from "@outlets/bim/Project";
import Checklist from "@outlets/bim/Checklist";
import Classification from "@outlets/bim/Classification";
import Lessons from "@outlets/bim/Lessons";
import Options from "@outlets/bim/Options";
import Deliverables from "@outlets/bim/Deliverables";
import Summary from "@outlets/costs/Summary";
import UnitValues from "@outlets/costs/UnitValues";
import { checkRoles } from "@utils/permissions";
import { useEffect } from "react";

const App = () => {

  const PrivateRoute = ({ element, role }) => {
    return checkRoles(role) ? element : <Navigate to="/dashboard" />;
  };


  return (
    <AuthProvider>
      <ScrollToTop>
        <Helmet />
        <Routes>
          <Route path="/" element={<AppContainer />}>
            <Route path="/access" element={<Access />}>
              <Route index element={<Login />} />
              <Route path="chave-alfa" element={<AlphaKey />} />
              <Route path="cadastrar" element={<Register />} />
              <Route path="recuperar-senha" element={<PasswordResetRequest />} />
              <Route path="definir-senha" element={<PasswordCreation />} />
              <Route path="codigo-verificacao" element={<CodeValidation />} />
            </Route>
            <Route path="/dashboard" element={<DashboardContainer />}>
              <Route path=":project_id?" element={<Details />} />
              <Route path="valor-agregado/:project_id?" element={<Value />} />
              <Route path="projecao-financeira/:project_id?" element={<Projection />} />
              <Route path="curva/:project_id?" element={<Curve />} />
              <Route path="adiantamentos/:project_id?" element={<Advancements />} />
              <Route path="backoffice/new-user/:project_id?" element={<PrivateRoute role={'admin'} element={<NewUser />} />} />
              <Route path="backoffice/users/:project_id?" element={<PrivateRoute role={'admin'} element={<Users />} />} />
              <Route path="backoffice/new-company/:project_id?" element={<PrivateRoute role={'admin'} element={<NewCompany />} />} />
              <Route path="backoffice/new-building/:project_id?" element={<PrivateRoute role={'admin'} element={<NewBuilding />} />} />
              <Route path="costs/obras" element={<PrivateRoute role={'costs'} element={<Entry />} />} />
              <Route path="costs/parametros" element={<PrivateRoute role={'costs'} element={<Params />} />} />
              <Route path="costs/unidades" element={<PrivateRoute role={'costs'} element={<MeasurementUnits />} />} />
              <Route path="costs/indices" element={<PrivateRoute role={'costs'} element={<Indexes />} />} />
              <Route path="costs/precos" element={<PrivateRoute role={'costs'} element={<UnitValues />} />} />
              <Route path="costs/itens" element={<PrivateRoute role={'costs'} element={<Items />} />} />
              <Route path="costs/cub" element={<PrivateRoute role={'costs'} element={<Indexers />} />} />
              <Route path="costs/resumos" element={<PrivateRoute role={'costs'} element={<Summary />} />} />
              <Route path="costs/comparacao" element={<PrivateRoute role={'costs'} element={<Comparison />} />} />
              <Route path="costs/fornecedores" element={<PrivateRoute role={'costs'} element={<Creditor />} />} />
              <Route path="bim/cadastro" element={<PrivateRoute role={'bim'} element={<Project />} />} />
              <Route path="bim/entregaveis" element={<PrivateRoute role={'bim'} element={<Deliverables />} />} />
              <Route path="bim/checklist" element={<PrivateRoute role={'bim'} element={<Checklist />} />} />
              <Route path="bim/classificacao" element={<PrivateRoute role={'bim'} element={<Classification />} />} />
              <Route path="bim/opcoes" element={<PrivateRoute role={'bim'} element={<Options />} />} />
              <Route path="bim/licoes" element={<PrivateRoute role={'t133t12'} element={<Lessons />} />} />
            </Route>
          </Route>
        </Routes>
      </ScrollToTop>
    </AuthProvider>
  );
};

export default App;

