import { permissionsTokens } from "@utils/permissions";

const UserRow = ({ user, edit, reset }) => {
  const { name, roles, email } = user;
  return <div className="user-row">
    <span>{name}</span>
    <span>{email}</span>
    <span>{Object.keys(permissionsTokens).map(i => {
      if (roles.includes(permissionsTokens[i])) {
        return `${i}, `
      }
    })}</span>
    <button onClick={edit}>Editar</button>
    <button onClick={reset}>Resetar senha</button>
  </div>
}

export default UserRow;