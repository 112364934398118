import { useAuthContext } from "@contexts/auth";

export const permissionsTokens = {
  user: 'RB_OC0ycPOmX35MndvSQzA_xYpA_gyQs',
  admin: 'cdju2OiNyJb2wXlo0MMpNZ7EHYBcDrQF',
  bim: '6ZCSalSZVH6j8qQVwHijSnpmEjctTBXy',
  costs: 'GB_qYR4k0ILTfYWnUE658vfISxdpZkBM',
  companyAdmin: 'bg8lJHtDW1D9GH17RDBBaA9rGV8fQ2Rr'
}

export const checkRoles = (roleNeeded) => {
  const { user } = useAuthContext();
  const rolesCheck = user && Array.isArray(user.roles) ? user.roles : [];
  if (rolesCheck.includes(permissionsTokens.admin)) return true;
  return rolesCheck.includes(permissionsTokens[roleNeeded]);
};

// -9pIw4QG95YtGqx_SliyYoIMn77gv82A
// 
// MdJ_Nnry7qO_gLAVIv2o72BEVDOPWk3-
// 
// HIt3L80moEF-zRkruaFt7s3WZZSg6YoC
// 
// nIA5UHz7UWiIseUgAs4BWlNStLp-FLxh
// 
// dDy_MvfIM1bsfERsKEioGZAFV8T4NzzR
// 